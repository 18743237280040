<template>
  <div class="singIn-section">
    <div class="uk-container">
      <div class="form-border">
        <div class="uk-text-center uk-title">
          <h2>{{ $t("form.createNewAccount") }}</h2>
        </div>
        <div class="uk-text-center d-flex justify-evenly uk-title my-5">
          <div class="d-flex align-center my-7">
            <v-icon x-large>{{ this.studentsIcon }}</v-icon>
            <router-link to="/signup-user">
              <v-btn
                class="no-hover"
                large
                :color="primaryColorOverlay"
                v-model="type"
                @click="type = 'user'"
              >
                {{ $t("form.student") }}
              </v-btn>
            </router-link>
          </div>
          <div class="d-flex align-center my-7">
            <v-icon x-large>{{ this.coursesIcon }}</v-icon>
            <router-link to="/signup-instructor">
              <v-btn
                class="no-hover"
                large
                :color="grayBack50"
                v-model="type"
                @click="type = 'instructor'"
              >
                {{ $t("form.instructor") }}
              </v-btn>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",
  data: () => ({
    type: "user"
  })
};
</script>

<style lang="scss">
.singIn-section {
  h2 {
    color: var(--primary-color);
  }
  .justify-evenly {
    justify-content: space-evenly;
    @media (max-width: 576px) {
      flex-direction: column;
    }
    div {
      // display: flex;
      flex-direction: column;
      // align-items: center;
    }
  }
  a:hover {
    text-decoration: none;
  }
  .no-hover {
    font-size: 19px !important;
    width: 140px !important;
    letter-spacing: 0;
  }
}
</style>
